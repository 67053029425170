<template>
  <div>
    <span>用户组筛选：</span>
    <a-select
      :value="filterId"
      placeholder="选择用户组进行筛选"
      style="width: 200px"
      @change="handleFilter"
      allow-clear
    >
      <a-select-option
        v-for="group in groups"
        :value="group.groupId"
        :key="group.groupId"
      >
        {{ group.orgName }} - {{ group.groupName }}
      </a-select-option>
    </a-select>
    <a-tabs :active-key="activeKey" @change="tabChange">
      <a-tab-pane v-for="tab in tabs" :key="tab.key" :tab="tab.title">
        <a-table
          :columns="columns"
          :data-source="dataSource"
          :loading="loading"
          rowKey="id"
          :pagination="pagination"
          @change="pageChange"
        >
          <template slot="reply" slot-scope="text, record">
            <div>
              <a-badge :count="record.unreadMsg ? 1 : 0" dot>
                <a @click="reply(record.id)">回复</a></a-badge
              >
            </div>
          </template>
        </a-table>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import { getFeedbackListByPage, getFeedbackGroup } from "@/api/feedback";
import moment from "moment";
export default {
  name: "List",
  components: {},
  props: {},
  data() {
    return {
      tabs: [
        {
          key: "notDone",
          title: "未解决",
        },
        {
          key: "done",
          title: "已解决",
        },
      ],
      filterId: undefined,
      groups: [],
      loading: false,
      columns: [
        {
          title: "反馈提交时间",
          dataIndex: "createAt",
          customRender: (text) => {
            return moment(text).format("YYYY-MM-DD");
          },
        },
        {
          title: "反馈内容",
          dataIndex: "msg",
          width: "20em",
          ellipsis: true,
        },
        {
          title: "反馈人",
          dataIndex: "creatorName",
        },
        {
          title: "反馈人所属机构用户组",
          dataIndex: "userGroupOrgInfoDto",
          customRender: (text) => {
            return `${text.orgName} - ${text.groupName}`;
          },
        },
        {
          title: "管理",
          scopedSlots: {
            customRender: "reply",
          },
        },
      ],
      dataSource: [],
      pagination: { pageSize: 10, total: 0, current: 1 },
      activeKey: "notDone",
    };
  },
  created() {
    getFeedbackGroup().then((res) => {
      this.groups = res.data;
    });
    this.loadData();
  },
  methods: {
    loadData() {
      let options = {
        platform: "ADMIN",
        isResolved: this.activeKey === "done",
        page: 0,
      };
      if (this.filterId) {
        options.filterGroupId = this.filterId;
      }
      getFeedbackListByPage(options).then((res) => {
        this.dataSource = res.data.content;
        this.pagination.total = res.data.totalElements;
      });
    },
    tabChange(activeKey) {
      this.activeKey = activeKey;
      let options = {
        platform: "ADMIN",
        isResolved: this.activeKey === "done",
        page: 0,
      };
      if (this.filterId) {
        options.filterGroupId = this.filterId;
      }
      getFeedbackListByPage(options).then((res) => {
        this.dataSource = res.data.content;
        this.pagination.total = res.data.totalElements;
      });
    },
    pageChange(pagination) {
      this.pagination.current = pagination.current;
      let options = {
        platform: "ADMIN",
        isResolved: this.activeKey === "done",
        page: this.pagination.current - 1,
      };
      if (this.filterId) {
        options.filterGroupId = this.filterId;
      }
      getFeedbackListByPage(options).then((res) => {
        this.dataSource = res.data.content;
      });
    },
    handleFilter(value) {
      this.filterId = value;
      this.loadData();
    },
    reply(id) {
      this.$router.push({
        path: "/system/feedback/detail",
        query: {
          feedbackId: id,
        },
      });
    },
  },
};
</script>

<style scoped></style>
